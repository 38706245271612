<!-- 商品列表 -->
<template>
  <div 
	  :lang="$i18n.locale"
	  v-loading.fullscreen="uploadloading"
    element-loading-text=""
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" 
		class>
		<div class="listHeader">
			<i></i><span>{{$t('ksaWhProductListPage.pageTitle')}}</span>
		</div>
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
      style="margin:10px 0 0 15px">
      <el-form-item label>
        <el-input type="textarea" row="2" v-model="companyProductCode" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.clientProductCode')" clearable />
      </el-form-item>
			<el-form-item label prop="productEn">
			  <el-input v-model="queryParams.productEn" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.productCnName')" clearable />
			</el-form-item>
			<el-form-item label prop="productNm">
			  <el-input v-model="queryParams.productNm" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.productEnName')" clearable />
			</el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleQuery">{{$t('commonInfo.searchBtn')}}</el-button>
				<el-button type="primary" icon="el-icon-refresh" @click="getList">{{$t('commonInfo.refreshBtn')}}</el-button>
				<el-button icon="el-icon-refresh" @click="handleReset">{{$t('commonInfo.dialogBtnReset')}}</el-button>
      </el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-plus" @click="handleAdd()">{{$t('commonInfo.createBtn')}}</el-button>
				<el-tooltip class="item" effect="light" :content="$t('ksaWhProductListPage.exportAllProductTips')" placement="top-start">
					<el-button type="primary" :disabled="exportLoading" :loading="exportLoading" @click="handleExportData()">{{$t('ksaWhProductListPage.exportProductDataBtn')}}</el-button>
				</el-tooltip>
				
			</el-form-item>
			<el-form-item>
				<el-tooltip class="item" effect="light" :content="$t('tipsInfo.importTips')" placement="top-start">
				  <!-- <el-upload
				    class="upload-demo"
				    :action="$baseUrl + '/toborder/wms/product/addMultiProduct'"
				    :before-upload="beforeUpload"
				    :on-success="handlesuccess"
				    :on-error="handleError"
				    :on-progress="handleProgress"
				    multiple
				    :show-file-list="false"
				    :file-list="fileList"
				    accept=".xls, .xlsx"
				    :headers="headers"
				    name="productFile"
				    style="margin-right:15px"
				  >
				    <el-button type="success">{{$t('ksaWhProductListPage.multipleImportProductDataBtn')}}</el-button>
				  </el-upload> -->
					<SingleUpload v-model="uploadProductFile" @input="getUploadExcel" :showUploadList="false"
						:tipText="$t('ksaWhProductListPage.multipleImportProductDataBtn')" :preFile="uploadPreFile"
						class="uploadProductBtn"></SingleUpload>
				</el-tooltip>
			</el-form-item>
			<el-form-item>
				<el-button
				  style="float: left;"
				  @click="handleDownloadUrl()"
				>{{$t('commonInfo.downloadTemplate')}}</el-button>
				<el-popover
				  placement="bottom"
				  width="360"
				  trigger="hover">
					<div class="productTips">
						<label>{{$t('ksaWhProductListPage.productUploadSuccessTips_title')}}</label>
						<span>{{$t('ksaWhProductListPage.productUploadSuccessTips_opt1')}}</span>
						<span>{{$t('ksaWhProductListPage.productUploadSuccessTips_opt2')}}</span>
					</div>
					<i slot="reference" class="el-icon-warning" style="font-size: 26px;color: #eb4e4e;margin: 6px 0 0 10px; float: left;"></i>
					</el-popover>
			</el-form-item>
			<el-form-item>
				<!-- 审核中 -->
				<div v-if="reviewProductFile != null && reviewProductFile.reviewStatus == 0" class="productAnalysisResult reviewing">
					<span>{{$t('ksaWhProductListPage.productAnalysis')}}</span>
					<i class="el-icon-loading"></i>
				</div>
				<!-- 审核失败 -->
				<div v-if="reviewProductFile != null && reviewProductFile.reviewStatus == -1" class="productAnalysisResult reviewFailed">
					<span>{{$t('ksaWhProductListPage.productAnalysisFailed')}}</span>
					<el-tooltip class="item" effect="light" :content="$t('commonInfo.checkFailedReason')" placement="top-start">
					  <i class="el-icon-document" @click="showFailedReason()"></i>
					</el-tooltip>
				</div>
			</el-form-item>
    </el-form>

    <!-- 展示 -->
    <el-table style="margin:0 15px;width: calc(100% - 30px)"
		 :row-key="getRowKey"
		 v-loading="loading" :data="dataList" border @selection-change="handleSelectionChange">
      <!-- <el-table-column type="selection" :reserve-selection="true" width="55" align="center" /> -->
			<el-table-column :label="$t('commonInfo.productReviewFlag')" align="center" prop="checkFlag" width="120">
				<template slot-scope="{ row }">
				  <span style="color: #7070e5;font-weight: bold;" v-if="row.checkFlag=='Y'">{{$t('commonInfo.productReviewed')}}</span>
					<span style="color: rgb(229 112 112);font-weight: bold;" v-if="row.checkFlag=='N'">{{$t('commonInfo.productUnreviewed')}}</span>
				</template>
			</el-table-column>
      <el-table-column :label="$t('commonInfo.clientProductCode')" align="center" prop="clientProductCode" min-width="140" />
      <el-table-column :label="$t('commonInfo.productEnName')" align="center" prop="productNm" :show-overflow-tooltip="true" min-width="140" />
      <el-table-column :label="$t('commonInfo.productCnName')" align="center" prop="productEnNm" :show-overflow-tooltip="true" min-width="140" />
	  <el-table-column prop="img " :label="$t('commonInfo.photo')" align="center" min-width="100" >
		<template slot-scope="{ row }">
				<img v-if="row.img   != null && row.img   != ''" :src="row.img  " @click="handleViewImg(row.img)" style="width: 60px;height: 60px;"/>
		</template>
	  </el-table-column>
      <el-table-column :label="$t('commonInfo.productPrice') + '(RMB)'" align="center" prop="productPrice" min-width="120" />
      <el-table-column :label="$t('commonInfo.productLength') + '(CM)'" align="center" prop="sizeL" min-width="120" />
      <el-table-column :label="$t('commonInfo.productBreadth') + '(CM)'" align="center" prop="sizeW" min-width="120" />
			<el-table-column :label="$t('commonInfo.productHeight') + '(CM)'" align="center" prop="sizeH" min-width="120" />
			<el-table-column :label="$t('commonInfo.productSpec')" align="center" prop="spec" min-width="130" />
			<el-table-column :label="$t('commonInfo.grossWeight') + '(KG)'" align="center" prop="grossWeight" min-width="140" />
			<el-table-column :label="$t('commonInfo.secondWeight') + '(KG)'" align="center" prop="weight" min-width="140" />
			<el-table-column :label="$t('commonInfo.unit')" align="center" prop="unit" min-width="100" />
			<el-table-column :label="$t('commonInfo.barcode')" align="center" prop="barCode" min-width="120" />
			<el-table-column :label="$t('commonInfo.remark')" align="center" prop="markup" min-width="120" />
      <el-table-column
        :label="$t('commonInfo.operationColumn')"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
				width="120px"
      >
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleEdit(row)"
          >{{$t('commonInfo.actionModify')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
	<el-image-viewer style="z-index: 9999;" v-if="showImg" :on-close="closeImg" :url-list="imgList" />
		<!-- 增加修改商品信息 -->
		<el-dialog
		  :lang="$i18n.locale"
		  :title="dialogTitle"
			:visible.sync="popup"
			:close-on-click-modal="false"
			width="920px"
			:append-to-body="true"
			@close="closeDialog"
		>
		  <el-form label-width="160px" :model="dialogForm" ref="ProductForm" :rules="rules" :inline="true" >
				<el-form-item :label="$t('commonInfo.productCode')" prop="productCode" v-if="isEdit">
					<el-input v-model="dialogForm.productCode"style="width: 250px;" :disabled="isEdit"  ></el-input>
				</el-form-item>
				<!-- <el-form-item label="公司编码" prop="company_id" v-if="isEdit">
					<el-input v-model="dialogForm.company_id"style="width: 250px;" disabled  ></el-input>
				</el-form-item> -->
				<el-form-item :label="$t('commonInfo.clientProductCode')" prop="companyProductCode">
					<el-input v-model="dialogForm.companyProductCode"style="width: 250px;" :disabled="isEdit" ></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.productCnName')" prop="productEn">
					<el-input v-model="dialogForm.productEn" style="width: 250px;" ></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.productEnName')" prop="productNm">
					<el-input v-model="dialogForm.productNm" style="width: 250px;" ></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.productPrice') + '(RMB)'" prop="productPrice">
					<el-input v-model="dialogForm.productPrice" style="width: 250px;" ></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.productLength') + '(CM)'" prop="sizeL">
					<el-input v-model="dialogForm.sizeL"style="width: 250px;" :disabled="dialogForm.confirmStatus == '1'" ></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.productBreadth') + '(CM)'" prop="sizeW">
					<el-input v-model="dialogForm.sizeW"style="width: 250px;" :disabled="dialogForm.confirmStatus == '1'" ></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.productHeight') + '(CM)'" prop="sizeH" >
					<el-input v-model="dialogForm.sizeH"style="width: 250px;" :disabled="dialogForm.confirmStatus == '1'"></el-input>
				</el-form-item>
				<!-- 确认过的显示体积 -->
				<el-form-item v-if="dialogForm.confirmStatus == '1'" :label="$t('commonInfo.chargeVolumeCcm')">
					<el-input v-model="dialogForm.volume"style="width: 250px;" :disabled="dialogForm.confirmStatus == '1'"></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.productSpec')" prop="spec" >
					<el-input v-model="dialogForm.spec"style="width: 250px;"></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.grossWeight') + '(KG)'" prop="grossWeight" >
					<el-input v-model="dialogForm.grossWeight"style="width: 250px;" :disabled="dialogForm.confirmStatus == '1'"></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.secondWeight') + '(KG)'" prop="weight" >
					<el-input v-model="dialogForm.weight"style="width: 250px;" :disabled="dialogForm.confirmStatus == '1'"></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.unit')" prop="unit" >
					<el-input v-model="dialogForm.unit"style="width: 250px;"></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.barcode')" prop="barCode" >
					<el-input v-model="dialogForm.barCode"style="width: 250px;"></el-input>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.remark')" prop="exp" >
					<el-input type="textarea" v-model="dialogForm.exp"style="width: 650px;"></el-input>
				</el-form-item>
				<!-- 上传照片 -->
				<div style="margin-left:160px">
					 <MultiUploadImg v-model="filename" :tipText="$t('commonInfo.choicePicture')" :preFile="imagesList" @input="getImages"></MultiUploadImg>
				</div>
				
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button
				  @click="closeDialog"
					:disabled="dialogLoading"
				>{{$t('commonInfo.dialogBtnCancel')}}</el-button>
				<el-button
				  type="primary"
					:disabled="dialogLoading"
					:loading="dialogLoading"
				  @click="submit"
				>{{dialogLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
			</div>
		</el-dialog>
		<div class="pageBottom">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="queryParams.page"
		    :page-sizes="[10, 20, 30, 40]"
		    :page-size="queryParams.limit"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="total"
		  ></el-pagination>
		</div>
		<!-- 导入Excel后错误的对话框 -->
		<el-dialog title="商品审核失败原因" :visible.sync="productReviewFailedPopup" :close-on-click-modal="false">
		  <el-table border :data="reviewFailedData">
				<el-table-column type="index" width="50" align="center"></el-table-column>
		    <el-table-column prop="reason" :label="$t('commonInfo.reason')" align="center"></el-table-column>
		  </el-table>
			<el-footer slot="footer" class="dialog-footer">
				<el-button
				  style="margin-right: 10px;"
				  @click="handleCloseReviewFailedDialog"
				>{{$t('commonInfo.dialogBtnClose')}}</el-button>
				<SingleUpload v-model="reUploadProductFile" @input="getReUploadExcel" :showUploadList="false"
					:tipText="$t('commonInfo.uploadAgain')" :preFile="reUploadPreFile"
					class="uploadProductBtn"></SingleUpload>
			</el-footer>
		</el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import MultiUploadImg from "@/components/upload/multiUploadImg";

import SingleUpload from "@/components/upload/singleUpload";

import {downloadStream} from "@/utils/downloadFileStream";

import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
	MultiUploadImg,
	ElImageViewer,
	SingleUpload
  },
  data() {
    //这里存放数据
    return {
		imagesList:[],
		filename:[],
		flag: false,
		showImg:false,
		// 查看图片
		imgList:[],
		ids:[],
      queryParams: {
        page: 1,
        limit: 10,
	    	companyProductCodeList:[],
	    	productNm:'',
	    	productEn:'',
      },
			companyProductCode:'',
			loading:false,
			uploadloading:false,
			exportLoading:false,
      total: 0,
			value1: [], // 时间
			dataList:[],
			dialogTitle:'',
			popup:false,
			dialogLoading:false,
			dialogForm:{
				productCode:'',
				companyProductCode:'',
				productEn:'',
				productNm:'',
				productPrice:'',
				sizeL:'',
				sizeW:'',
				sizeH:'',
				spec:'',
				grossWeight:'',
				weight:'',
				unit:'',
				barCode:'',
				exp:'',
				productImageList:[],
				confirmStatus:'',
				volume:'',
			},
			isEdit:false,
			// 手动给接口添加token值
			headers: {},
			dialogTableVisible:false,
			gridData:[],
			fileList: [],
			skuModelUrl:'',
			
			// 上传商品excel解析创建产品信息
			uploadProductFile:'',
			uploadPreFile:[],
			// 获取后台解析文件状态
			reviewProductFile:{},
			// 显示错误原因
			productReviewFailedPopup:false,
			reviewFailedData:[],
			// 重新上传文件
			reUploadProductFile:'',
			reUploadPreFile:[],
    };
  },
  //监听属性 类似于data概念
  computed: {
		rules() {// 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
		  //自定义验证规则 只能填写数字 保留小数点
			// 保留一位小数点
		  const validateCode1 = (rule, value, callback) => {
		    let reg = /(^[1-9]([0-9]+)?(\.[0-9]{0,1})?$)|(^(0){1}$)|(^[0-9]\.[0-9]?$)/
		    if(!reg.test(value)){
		  		callback(new Error(this.$t('commonInfo.numberToFixedOneLimit')))
		    }else{
		      callback()
		    }
		  };
			// 保留三位小数点
			const validateCode3 = (rule, value, callback) => {
			  let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,3})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
			  if(!reg.test(value)){
					callback(new Error(this.$t('commonInfo.numberToFixedThreeLimit')))
			  }else{
			    callback()
			  }
			};
		  return {
				companyProductCode: [
				  { required: true, message: this.$t('commonInfo.clientProductCode') + this.$t('tipsInfo.nullPlaceholder'), trigger: "blur" }
				],
				productNm: [
				  { required: true, message: this.$t('commonInfo.productEnName') + this.$t('tipsInfo.nullPlaceholder'), trigger: "blur" }
				],
				productEn: [
				  { required: true, message: this.$t('commonInfo.productCnName') + this.$t('tipsInfo.nullPlaceholder'), trigger: "blur" }
				],
				sizeL: [
				  { required: true, message: this.$t('commonInfo.productLength') + this.$t('tipsInfo.nullPlaceholder'), trigger: "blur" },
					{
						validator:validateCode1, 
						trigger:'blur',
					}
				],
				sizeW: [
				  { required: true, message: this.$t('commonInfo.productBreadth') + this.$t('tipsInfo.nullPlaceholder'), trigger: "blur" },
					{
						validator:validateCode1, 
						trigger:'blur',
					}
				],
				sizeH: [
				  { required: true, message: this.$t('commonInfo.productHeight') + this.$t('tipsInfo.nullPlaceholder'), trigger: "blur" },
					{
						validator:validateCode1, 
						trigger:'blur',
					}
				],
				grossWeight: [
				  { required: true, message: this.$t('commonInfo.grossWeight') + this.$t('tipsInfo.nullPlaceholder'), trigger: "blur" },
					{
						validator:validateCode3, 
						trigger:'blur',
					}
				],
				weight: [
				  { required: true, message: this.$t('commonInfo.secondWeight') + this.$t('tipsInfo.nullPlaceholder'), trigger: "blur" },
					{
						validator:validateCode3, 
						trigger:'blur',
					}
				],
			}
		}
	},
  //监控data中的数据变化
  watch: {
		value1() {
		  if (this.value1.length === 2) {
		    // console.log(this.value1);
		    this.queryParams.startTime = this.value1[0];
		    this.queryParams.endTime = this.value1[1];
		  }
		},
	},
  //方法集合
  methods: {
		// 监听日期选择器
		changeDate(val) {
		  // console.log(val);
		  if (!val) {
		    this.value1 = [];
		    this.queryParams.startTime = "";
		    this.queryParams.endTime = "";
		  }
		},
		// 分页条数
		handleSizeChange(size) {
		  this.queryParams.limit = size;
		  this.queryParams.page = 1;
		  this.getList();
		},
		// 分页页数
		handleCurrentChange(page) {
		  this.queryParams.page = page;
		  this.getList();
		},
    async getList() {
			// 获取导入商品审核情况
			this.getImportProductStatus();
			
			this.loading = true;
			this.dataList = [];
			const res = await this.$http.post("/toborder/wms/product/queryProductInfos", this.queryParams);
			
			if (res.code === 200) {
			  let data = res.data.list;
			  // 总共有多少条数据，分页
			  this.total = res.data.totalCount;
			  this.loading = false;
			  this.dataList = data;
			  this.dataList.forEach(item => {
				  if(item.productImages != null && item.productImages != ''){
						let img = JSON.parse(item.productImages);
						item.img = img;
					}else{
						item.img = '';
					}
    		})
			} else {
			  this.loading = false;
			  this.$message.error(res.msg);
			}
		},
		// 搜索
		handleQuery(){
			this.queryParams.page = 1;
			if (this.companyProductCode != '') {
				this.queryParams.companyProductCodeList = this.strToArr(this.companyProductCode);
			}else{
				this.queryParams.companyProductCodeList = [];
			}
			this.getList();
		},
		//重置
		handleReset(){
			this.queryParams = {
				page:1,
				limit:10,
				companyProductCodeList:[],
				productNm:'',
				productEn:'',
			}
			this.companyProductCode = '';
			this.getList();
		},
		// 多选选中的数据
		handleSelectionChange(selection) {
		  // this.ids = selection.map(item => item.id);
		  // this.single = selection.length !== 1;
		  // this.multiple = !selection.length;
		  // console.log(this.ids);
		},
		// 获取唯一标识
		getRowKey(row){
		  return row.id;
		},
		handleAdd(){
			this.dialogTitle = this.$t('ksaWhProductListPage.dialogProductAddTitle');
			this.popup = true;
			this.isEdit = false;
		},
		// 修改商品
		handleEdit(row){
			// this.getInfo();
			// this.dialogForm = row;
			this.dialogForm.companyProductCode = row.clientProductCode;
			this.dialogForm.grossWeight = row.grossWeight;
			this.dialogForm.productCode = row.productCode;
			this.dialogForm.productEn = row.productEnNm;
			this.dialogForm.productNm = row.productNm;
			this.dialogForm.productPrice = row.productPrice;
			if(row.confirmStatus == '1' && row.volumeMode == 1){
				this.dialogForm.sizeH = '~';
				this.dialogForm.sizeL = '~';
				this.dialogForm.sizeW = '~';
			}else{
				this.dialogForm.sizeH = row.sizeH;
				this.dialogForm.sizeL = row.sizeL;
				this.dialogForm.sizeW = row.sizeW;
			}
			this.dialogForm.weight = row.weight;
			this.dialogForm.barCode = row.barCode;
			this.dialogForm.unit = row.unit;
			this.dialogForm.exp = row.markup;
			this.dialogForm.spec = row.spec;
			this.dialogForm.confirmStatus = row.confirmStatus;
			this.dialogForm.volume = row.volume;
			this.dialogForm.productImageList = [];
			if(row.img != null && row.img!=''){
				this.imagesList.push({
					url:row.img[0]
				});
			}
			
			this.dialogTitle = this.$t('ksaWhProductListPage.dialogProductModifyTitle');
			this.popup = true;
			this.isEdit = true;
		},
		async getModelUrl(){
			const res = await this.$http.get("/toborder/system/dict/data/list",{
			  params: {
					page:1,
					limit:10,
					dictType:'sys_template_url'
				}
			});
			
			if (res.code === 200) {
				let that = this;
				let data = res.rows;
				data.forEach(item => {
					if(item.dictLabel == 'fulfillment批量录入商品信息模版'){
						that.skuModelUrl = item.remark;
					}
				})
			} else {
			  this.$message.error(res.msg);
			}
		},
		// 获取导入商品文件路径
		getUploadExcel(data){
			if(data != '' && data != null){
				this.uploadProductExcelUrl(data);
			}
		},
		// 将上传路径提交后台
		uploadProductExcelUrl(data){
			let query = {
				fileUrl:data
			}
			this.uploadloading = true;
			const res = this.$http.post("/toborder/wms/productexport/addMultiProductNew",query).then(res => {
			    if(res.code == 200){
						this.uploadloading = false;
						this.$message.success(this.$t('ksaWhProductListPage.productUploadSuccessTips'));
					}else{
						this.uploadloading = false;
						this.$message.error(res.msg);
					}
			  }).catch(error => {
					this.uploadloading = false;
					this.$message.error(error.msg);
				});
		},
		// 查看失败原因
		showFailedReason(){
			this.reviewFailedData = [];
			if(this.reviewProductFile.reviewRemarks != '' && this.reviewProductFile.reviewRemarks != null){
				let list = this.reviewProductFile.reviewRemarks.split('#');
				list.forEach(item => {
					this.reviewFailedData.push({
						reason:item
					})
				})
			}
			this.productReviewFailedPopup = true;
		},
		// 获取重新上传文件路径
		getReUploadExcel(data){
			if(data != '' && data != null){
				this.reUploadProductExcelUrl(data);
			}
		},
		// 重新上传
		reUploadProductExcelUrl(data){
			let query = {
				fileUrl:data
			}
			this.uploadloading = true;
			const res = this.$http.post("/toborder/wms/productexport/updateMultiProduct",query).then(res => {
			    if(res.code == 200){
						this.uploadloading = false;
						this.$message.success(this.$t('ksaWhProductListPage.productUploadSuccessTips'));
						this.getList();
						this.productReviewFailedPopup = false;
					}else{
						this.uploadloading = false;
						this.$message.error(res.msg);
					}
			  }).catch(error => {
					this.uploadloading = false;
					this.$message.error(error.msg);
				});
		},
		// 关闭窗口
		handleCloseReviewFailedDialog(){
			this.productReviewFailedPopup = false;
		},
		// 获取后台解析导入商品文件得情况
		getImportProductStatus(){
			const res = this.$http.post("/toborder/wms/productexport/getExportInfo",{}).then(res => {
			  if(res.code == 200){
					if(res.data){
						this.reviewProductFile = res.data;
					}else{
						this.reviewProductFile = null;
					}
					
				}
			})
		},
		
		// 导出商品信息
		handleExportData(){
			this.exportLoading = true;
			const res = this.$http.post("/toborder/wms/product/export",null,{
				responseType:'blob',
			}).then(res => {
			    if (res.code != 500) {
						downloadStream(res,'xlsx','商品信息')
			      // window.open("http://awb.dlvd.com/vip_excel/download/" + res.msg);
			    }
					this.exportLoading = false;
			  }).catch(error => {
					this.exportLoading = false;
					this.$message.error(error.msg);
				});
		},
		// 下载模板
		handleDownloadUrl(){
			window.open(this.skuModelUrl);
		},
		// 上传图片
		getImages(data) {
			this.imagesList = [];
			if (data.length > 0) {
			data.forEach(item => {
				this.imagesList.push({
					url:item.url
				})
			})
			}
		},
		// 查看图片
		handleViewImg(img){
			this.imgList = [];
			if(img.indexOf(',') == -1){
			  this.imgList.push(img[0])
			}else{
			  this.imgList = img.split(',');
			}
			this.showImg = true;
		},
		//关闭图片
		closeImg() {
			this.showImg = false;
		},
		// 提交
		submit(){
			console.log(this.imagesList);
			// 新增时去除系统商品编号和公司编码 
			let url = '';
			if(this.isEdit){
				url = '/toborder/wms/product/update';
			}else{
				delete this.dialogForm.productCode;
				delete this.dialogForm.company_id;
				url = '/toborder/wms/product/addProduct';
			}
			this.$refs["ProductForm"].validate(valid => {
				if(valid){
					console.log(this.imagesList);
					this.dialogLoading = true;
					if(this.imagesList.length>0){
						this.dialogForm.productImageList.push(this.imagesList[0].url);
					}
					
					this.$http.post(url,this.dialogForm).then(res => {
						if(res.code == 200){
							this.$message.success(this.$t('tipsInfo.success'));
							this.imagesList = [];
							this.filename = [];
							this.popup = false;
							this.dialogLoading = false;
							this.getList();
						}else{
							this.dialogLoading = false;
							this.$message.error(res.msg);
						}
					}).catch(error => {
						this.dialogLoading = false;
						this.$message.error(error.msg);
					})
				}
			})
		},
		// 关闭窗口
		closeDialog(){
			this.popup = false;
			this.dialogForm = {
				productCode:'',
				companyProductCode:'',
				productEn:'',
				productNm:'',
				productPrice:'',
				sizeL:'',
				sizeW:'',
				sizeH:'',
				spec:'',
				grossWeight:'',
				weight:'',
				unit:'',
				barCode:'',
				exp:'',
				productImageList:[]
			};
			this.imagesList = [];
			/* this.filename = []; */
		},
		//上传成功
		handlesuccess(files, fileList) {
			this.uploadloading = false;
		  if (files.code == 200) {
		    if (files.data.success) {
		      this.$message.success(this.$t('ksaWhProductListPage.productUploadSuccessTips'));
		      this.getList();
		      return;
		    } else if (files.data.success == false) {
		      this.gridData = files.data.productErrorInfoList;
		      this.dialogTableVisible = true;
		    }
		  } else if (files.code == 500) {
		    this.$message.error(files.msg);
		  }
		  // console.log(files);
		
		  if (files.code == 401) {
		    this.$message.error(this.$t('tipsInfo.loginOutTips'));
		    window.sessionStorage.removeItem("token");
		    return this.$router.push({
		      path: "/login"
		    });
		  }
		  // console.log(files);
		},
		// 上传时触发
		beforeUpload(file) {
		  var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
		  const extension = testmsg === "xls";
		  const extension2 = testmsg === "xlsx";
		  if (!extension && !extension2) {
		    this.$message.warning(
		      this.$t('tipsInfo.uploadExcelTypeTips')
		    );
		  }
		  return extension || extension2;
		},
		// 上传失败
		handleError(err, file, fileList) {
			this.uploadloading = false;
		  console.log(err);
		  console.log(file);
		  console.log(fileList);
		},
		// 上传时
		handleProgress(event, file, fileList) {
		  // this.$message.warning("Uploading, please hold on");
			this.uploadloading = true;
		},
  },
  created() {
		let token = window.sessionStorage.getItem("token");
		this.headers = {
			Authorization: token,
		}
		
		this.getList();
		this.getModelUrl();
	},
  mounted() {}
};
</script>
<style scoped>
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.pageBottom {
	  padding: 20px;
	}
	.productTips{
		width: 100%;
	}
	.productTips label{
		display: block;
		line-height: 30px;
		height: 30px;
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px solid #f3f3f3;
		margin-bottom: 10px;
	}
	.productTips span{
		display: block;
		line-height: 22px;
		height: 22px;
	}
	.uploadProductBtn {
		display: inline-block;
	}
	.uploadProductBtn /deep/ .el-button{
		background-color: #67c23a;
		border-color: #67c23a;
		font-size: 14px;
		padding: 12px 20px;
	}
	.productAnalysisResult{
		display: inline-block;
		border: 2px solid #0077AA;
		height: 30px;
		line-height: 30px;
		float: left;
		margin: 0 10px;
		padding: 5px 10px;
		border-radius: 8px;
	}
	.reviewing {
		width: 140px;
		color: #c17f1c;
		border-color: #c17f1c;
	}
	.reviewing span{
		margin-right: 10px;
	}
	.reviewFailed{
		width: 150px;
		color: #eb4e4e;
		border-color: #eb4e4e;
	}
	.reviewFailed span{
		margin-right: 12px;
	}
	.productAnalysisResult /deep/ .el-button{
		padding: 0;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
	}
</style>